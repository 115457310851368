/* General styles */

.how-to {
    padding: 50px 150px;
    color: white;
}

.how-to-title {
    margin-bottom: 40px;
    font-size: 24px;
}

.faq h1 {
    margin: 80px 0 40px;
}

.faq-box {
    margin: 24px 0;
}

.faq-title {
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin: 0;
}

.faq-content {
    padding-top: 10px;
}
.nav-tabs, .nav-tabs .nav-link {
    border: none;
    color: #666666;
    font-size: 24px;
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
    color: #999999;
}

.nav-tabs .nav-link.active {
    font-weight: bold;
}

.tab-pane {
    padding: 20px;
    text-align: center;
}

.content-text {
    color: #333;
    font-size: 1.2rem;
    line-height: 1.6;
}

.image-placeholder {
    border: 2px dashed #ccc;
    text-align: center;
    padding: 2rem;
    margin: 1rem 0;
}

/* Footer styles */
.footer-links {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.footer-top-link {
    padding-top: 15px;
}

.footer-link {
    color: #fff;
    text-decoration: none;
    font-size: 1.1rem;
}

.navbar-toggler-icon {
    color: white;
}

.white-box {
    border: 1px solid #000;
}

.posts .post-item .post-wrap{
    background: transparent;
    padding: 10px;
    margin-bottom: 20px;
}

.posts .post-item .image {
    width: 100%;
    display: block;
    position: relative;
}

.post-container {
    margin-top: 30px;
    -webkit-transition: .3s all;
    -o-transition: .3s all;
    transition: .3s all;
    position: relative;
    height: 400px;
}

.post-container img {
    -o-object-fit: cover;
    object-fit: cover;
    object-position: top;
    -o-object-position: top;
    width: 100%;
    height: 100%;
    top: 0;
    vertical-align: top;
}

.info-content {
    background: transparent;
    margin-bottom: 5px;
}

.info-content span {
    float: left;
    color: #E4E4E4;
}

.info-content button {
    float: right;
    width: 180px;
    height: 50px;
}

.info-bottom-content button {
    width: 100%;
    height: 50px;
    margin-top: 5px;
    background: #160040;
}

#timeline-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden; /* Eğer çok fazla video varsa ve tüm barları göstermek mümkün değilse bu satırla taşan kısımların gizlenmesini sağlayabilirsiniz. */
    max-width: 350px;
    margin: auto;
}

.timeline-container {
    flex: 1;
    margin: 0 2px;
    position: relative; /* Bu satırı ekleyin */
    height: 5px; /* Bar yüksekliği; ihtiyacınıza göre değiştirilebilir */
    background: #f1f1f1; /* Bu arkaplan rengini timeline-bar-background div'i yerine buraya ekleyin */
}

.timeline-bar-background, .timeline-bar-fill {
    height: 100%; /* Yükseklik %100 olsun */
}

.timeline-bar-fill {
    position: absolute; /* Bu satırı ekleyin */
    top: 0; /* Bu satırı ekleyin */
    left: 0; /* Bu satırı ekleyin */
    background: blue; /* Dolu barın rengi */
    width: 0%; /* Başlangıçta bu genişlikte olacak; JavaScript ile bu değeri değiştireceksiniz */
    height: 100%;
    z-index: 2;
}

.timeline-bar {
    width: 0%;            /* Başlangıçta genişliği sıfır */
    height: 4px;          /* İstediğiniz yüksekliği belirtin */
    background: white; /* Başlangıçta şeffaf arka plan */
    transition: width 0.3s;  /* İlerleme animasyonu için geçiş süresi */
}

.timeline-bar.active {
    background: #007BFF;  /* Aktif olduğunda mavi arka plan */
}

.timeline-bar-background {
    width: 100%;         /* Tam genişlik */
    height: 4px;         /* İstediğiniz yüksekliği belirtin */
    background: #FFF;   /* Beyaz arka plan */
    position: relative;
    z-index: 1;          /* Üstteki katmana göre altta kalacak */
}

.top-highlights-wrapper {
    padding: 20px;
    width: 100%;
    margin: 0 0 20px 0;
    list-style: none;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;
    text-align: center;
    margin-top: 50px;
}

.top-highlights-wrapper .highlight-item {
    display: inline-block;
    margin: 0 20px 0 0;
}

.top-highlights-wrapper .highlight-item a {
    border-radius: 100px;
    width: 125px;
    height: 125px;
    margin-right: 20px;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    color: #666;
}
.top-highlights-wrapper .highlight-item.active a {
    color: #1a202c;
}
.top-highlights-wrapper .highlight-item .image img {
    max-width: 100%;
    border-radius: 100px;
    width: 125px;
    height: 125px;
    display: inline-block;
    opacity: 0.9 !important;
}
.top-highlights-wrapper .highlight-item .image img:hover {
    opacity: 1 !important;
    border: 3px dashed darkgoldenrod;
    transition: 0.2s linear 0.1s;
}
.top-highlights-wrapper .highlight-item.active .image img {
    border: 6px dashed darkgoldenrod;
    opacity: 1 !important;
}
.top-highlights-wrapper .highlight-item .title {
    margin-top: 10px;
}

.load-container {
    width: 100%;
    padding: 10px;
    text-align: center;
    display: none;
}

.load-container button {
    max-width: 200px;
    margin: auto;
    padding: 20px;
    font-size: 24px;
}




.about h2 {
    margin-top: 20px;
}

.about-image img {
    width: 100%;
    height: auto;
}

#contactForm .form-group label.control-label {
    color: #8c8c8c
}

#contactForm .form-control {
    font-weight: 500;
    height: auto
}

#contactForm button {
    border: 1px solid #ccc;
}

.section-title {
    margin-top: 30px;
}

.about-image {
    width: 100%;
    text-align: center;
    margin: 30px 0;
}

.profile-container {
    padding-top: 30px;
    width: 50%;
    margin: auto;
}

.profile-image {
    align-self: center;
    grid-area: avatar;
    height: 150px;
    justify-self: center;
    text-align: center;
}
.circle-radius {
    border-radius: 100px;
}

.profile-username {
    text-align: center;
    padding: 5px;
    font-size: 1.375rem;
    margin-bottom: 25px;
}

.profile-info {
    display:flex;
}

.profile-info-box {
    margin: auto;
}

.profile-info-container {
    padding: 0 30px;
}

.profile-info-title {
    font-size: 1.375rem;
    text-align: center;
    padding-bottom: 15px;
}

.profile-info-content {
    font-size: 1.250rem;
    font-weight:bold;
    text-align: center;
}

.profile-fullname {
    font-size: 20px;
    margin-top: 20px;
}

.profile-biography {
    padding-top: 15px;
    font-size: 1rem;
}

.statistic-container {
    padding-top: 30px;
    width: 50%;
    margin: auto;
    padding-bottom: 30px;
}
.statistic-box {
    display: flex;
}

.statistic-content {
    font-size: 1.1rem;
}

.search-fields {
    position: relative;
}

.tooltip {
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 6px !important;
    position: absolute;
    z-index: 1 !important;
    bottom: 90px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    padding: 15px;
    font-size: 16px;
}

.tooltip::after {
    content: "";
    position: absolute;
    z-index: 1 !important;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
}

@media (max-width: 767.98px) {
    .profile-container {
        width: 80%;
        padding-top: 10px;
    }

    .profile-info-container {
        padding: 0;
    }

    .profile-username {
        margin-bottom: 15px;
        margin-top: 15px;
    }

    .profile-info-title {
        padding-bottom: 5px;
    }

    .profile-info-content {
        padding-bottom: 15px;
    }

    .statistic-container {
        width: 80%;
        padding-top: 10px;
    }

    .navbar-brand {
        font-size: 1.5rem;
        margin-right: 0;
        font-weight: bold;
        letter-spacing: -1px;
    }

    .how-to {
        padding: 20px 30px;
        color: white;
    }

    .faq {
        padding: 0 20px;
    }
}

